























































// TODO: Trans
import Vue from "vue";
import * as pmTypes from "./pmTypes";
import { Action } from "vuex-class";
import { Component, PropSync, Watch, Prop } from "vue-property-decorator";
import {
  Stripe,
  StripeIbanElement,
  StripeCardElement,
  StripeElementChangeEvent,
} from "@stripe/stripe-js";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import api from "@/core/utils/api";
import * as Settings from "@/settings";

@Component({
  components: {
    StripeElementCard,
  },
})
export default class BillingDetailsForm extends Vue {
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => null }) stripe!: Stripe | null;
  @PropSync("pmProp", { type: String, default: () => null }) pmType!: string;
  @PropSync("stepProp", { type: Number }) step!: number;
  @PropSync("pmIdProp", { type: String }) pmId!: string;
  @Action("displaySnackbar") displaySnackbar!: Function;

  publishableKey = Settings.paymentSettings.publishableKey;
  types = pmTypes;
  element: StripeIbanElement | StripeCardElement | null = null;
  elementError = "";
  pmLoading = false;
  elementLoading = false;
  ibanAcc = { name: "", email: "" };

  cancel() {
    this.step = 1;
    this.pmLoading = false;
    this.pmId = "";
  }

  async createCardPm() {
    if (!this.stripe || !this.element) return;

    const card = this.element as StripeCardElement;
    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (error || !paymentMethod) {
      const msg = error?.message || "Error while creating card payment method";
      throw new Error(msg);
    }
    const { id } = paymentMethod;
    this.pmId = id;
  }
  async createSepaPm() {
    if (!this.stripe || !this.element) {
      const msg = "Something went wrong";
      throw new Error(msg);
    }
    const { name, email } = this.ibanAcc;
    if (!email || !name) {
      const message = "Incomplete data. Missing account holder information.";
      this.elementError = message;
      throw new Error(message);
    } else this.elementError = "";

    try {
      const endpoint = "api/Company/CreateSetupIntent";
      const clientSecret = (await api.get(endpoint)) as string;
      const sepa_debit = this.element as StripeIbanElement;
      const { setupIntent, error } = await this.stripe.confirmSepaDebitSetup(
        clientSecret,
        {
          payment_method: { sepa_debit, billing_details: { email, name } },
          mandate_data: {
            customer_acceptance: {
              type: "online",
              online: { user_agent: navigator.userAgent },
            },
          },
        },
      );

      if (error || !setupIntent)
        throw new Error("Could not confirm SEPA Debit setup.");
      const { payment_method } = setupIntent;
      if (!payment_method) throw new Error("Could not retrieve Payment Method");
      this.pmId = payment_method as string;
    } catch (error) {
      const msg = "Could not create setup intent";
      throw new Error(msg);
    }
  }
  async next() {
    if (!this.element) return;
    this.stripeElBlur();

    // @ts-ignore
    if (this.elementError) {
      const msg = this.$t("forms.errors").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.pmLoading = true;
    try {
      if (this.pmType === pmTypes.card) await this.createCardPm();
      else await this.createSepaPm();
      this.step = 2;
    } catch (error: any) {
      this.displaySnackbar(error.message);
      console.log(error);
    }
    this.pmLoading = false;
  }

  submit() {
    // this will trigger the process
    // Assuming this.$refs.elementRef is a reference to the form element
    if (this.$refs.elementRef instanceof HTMLElement)
      (this.$refs.elementRef as HTMLFormElement).submit();
    // Handle error if the reference is not an HTMLFormElement
    else console.error("Invalid form reference");
  }

  tokenCreated(token: any) {
    console.log(token);
    // handle the token
    // send it to your server
  }

  stripeElChange(evt: StripeElementChangeEvent) {
    const { error, empty } = evt;
    if (error) this.elementError = error.message;
    else if (empty)
      this.elementError = this.$t("snack.stripe.elempty").toString();
    else this.elementError = "";
  }
  stripeElBlur() {
    // @ts-ignore
    if (this.elementError) return;
    // @ts-ignore
    const { _empty, _invalid, _complete } = this.element!;
    if (_empty) this.elementError = this.$t("snack.stripe.elempty").toString();
    else if (_invalid)
      this.elementError = this.$t("snack.stripe.elinvalid").toString();
    else if (!_complete)
      this.elementError = this.$t("snack.stripe.elincomplete").toString();
    else this.elementError = "";
  }
  mountCard() {
    if (this.stripe === null) return;
    const elements = this.stripe.elements();
    this.element = elements.create("card");
    this.element.mount("#stripe-element");
    this.element.on("change", this.stripeElChange);
    this.element.on("blur", this.stripeElBlur);
  }
  mountIban() {
    if (this.stripe === null) return;
    const elements = this.stripe.elements();
    this.element = elements.create("iban", {
      supportedCountries: ["SEPA"],
      placeholderCountry: "DE",
    });
    this.element.mount("#stripe-element");
    this.element.on("change", this.stripeElChange);
    this.element.on("blur", this.stripeElBlur);
  }
  loadElement() {
    this.elementLoading = true;
    try {
      if (this.pmType === pmTypes.card) this.mountCard();
      else this.mountIban();
    } catch (error) {
      console.log(error);
    }
    this.elementLoading = false;
    this.elementError = "";
  }

  mounted() {
    this.loadElement();
  }

  @Watch("pmType", { immediate: true })
  pmTypeChange() {
    this.loadElement();
  }
  @Watch("stripe", { immediate: true })
  stripeChange() {
    this.loadElement();
  }
}
