
























































// TODO: Trans
import Vue from "vue";
import api from "@/core/utils/api";
import { Product } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, PropSync, Watch, Prop } from "vue-property-decorator";

@Component
export default class PurchaseOverview extends Vue {
  @Prop({ default: () => "" }) tier!: string;
  @Prop({ default: () => "" }) pmId!: string;
  @Prop({ default: () => 0 }) companyId!: number;
  @Prop({ default: () => false }) loading!: boolean;
  @PropSync("stepProp", { type: Number }) step!: number;

  @Getter("products/retrieved") retrieved!: boolean;
  @Getter("products/products") products!: Product[];
  @Action("products/get") getProducts!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  couponCode = "";
  coupon: any | null = null;
  get discountPrice() {
    if (!this.coupon) return null;

    const { amount_off, percent_off } = this.coupon;
    if (amount_off) {
      const newPrice = this.price - amount_off;
      return (newPrice / 100).toFixed(2);
    }
    const newPrice = (this.price * (percent_off / 100)) / 100;
    return newPrice.toFixed(2);
  }
  get discountTime() {
    if (!this.coupon) return null;
    const { duration_in_months } = this.coupon;
    const str = this.$t("snack.stripe.discount", {
      d: duration_in_months,
    }).toString();
    return str;
  }

  cancelPurchase() {
    this.step = 1;
  }
  get plan() {
    if (!this.retrieved) return {};
    return this.products.find(x => x.monthlyPrice === this.tier);
  }
  get price() {
    if (!this.retrieved) return 0;
    const product = this.products.find(x => x.monthlyPrice === this.tier);
    if (!product) return 0;
    return product.unit_amount;
  }
  async confirmPurchase() {
    const endpoint = "/api/Subscriptions/PurchaseSubscription";

    // TODO: Set loading overlay
    const data = {
      currency: "EUR",
      priceId: this.tier,
      isGermanTaxRate: false,
      couponId: this.couponCode,
      companyId: this.companyId,
      paymentMethodId: this.pmId,
    };

    try {
      await api.post(endpoint, data);
      const msg = this.$t("snack.stripe.purchase").toString();
      this.displaySnackbar(msg);
    } catch (error) {
      console.log(error);
      const msg = this.$t("snack.stripe.pfail").toString();
      this.displaySnackbar(msg);
    }
  }

  async checkCoupon() {
    if (!this.couponCode) {
      const msg = this.$t("snack.stripe.entercode").toString();
      this.displaySnackbar(msg);
      return;
    }

    try {
      const end = `/api/Coupons/${this.couponCode}`;
      const coupon = await api.get(end);
      this.coupon = coupon;
    } catch (error) {
      const fallback = this.$t("snack.stripe.codeinvalid").toString();
      const msg = error.message || fallback;
      this.displaySnackbar(msg);
    }
  }

  created() {
    if (!this.retrieved && !this.retrieved) this.getProducts();
  }

  @Watch("couponCode", { immediate: true })
  rerender() {
    this.$forceUpdate();
  }
}
