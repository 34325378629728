import { render, staticRenderFns } from "./BillingDetailsForm.vue?vue&type=template&id=f28534fe&scoped=true&"
import script from "./BillingDetailsForm.vue?vue&type=script&lang=ts&"
export * from "./BillingDetailsForm.vue?vue&type=script&lang=ts&"
import style0 from "./BillingDetailsForm.vue?vue&type=style&index=0&id=f28534fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f28534fe",
  null
  
)

export default component.exports