



















































// TODO: Create
import Vue from "vue";
import Tiers from "./tiers";
import api from "@/core/utils/api";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { EmailsInput } from "@/components/common";
import { required, minLength } from "vuelidate/lib/validators";
import { Component, PropSync, Watch, Prop } from "vue-property-decorator";

type SelectItem = {
  text: string;
  value: number;
  disabled?: boolean;
};
@Component({
  mixins: [validationMixin],
  components: { EmailsInput },
  validations: {
    companyName: { required, minLength: minLength(3) },
  },
})
export default class CreateCompanyForm extends Vue {
  @Getter("profile/createCompanyLoading") createCompanyLoading!: boolean;
  @Getter("profile/createCompanySuccess") createCompanySuccess!: boolean;
  @Action("profile/createCompany") createCompany!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  @Prop({ default: () => false }) loading!: boolean;
  @PropSync("stepProp", { type: Number }) step!: number;
  @PropSync("maxEmployeesProp", { type: Number }) maxEmployees!: number;
  @PropSync("createDialogProp", { type: Boolean }) createDialog!: boolean;

  creating = false;
  vatId = "";
  companyName = "";
  selectedUsers: string[] = [];
  items: SelectItem[] = [
    { text: "5 Employees", value: 5 },
    { text: "10 Employees", value: 10 },
    { text: "100 Employees", value: 100 },
  ];
  get nameErrors() {
    const errors: any[] = [];
    if (!this.$v.companyName!.$dirty) return errors;
    !this.$v.companyName!.required && errors.push("Required Field");
    !this.$v.companyName!.minLength &&
      errors.push("Must be at least 3 characters");
    return errors;
  }

  async createConfirm() {
    if (this.createCompanyLoading) return;
    this.$v.$touch();
    if (this.$v.$invalid) {
      const msg = this.$t("forms.invaliddata").toString();
      this.displaySnackbar(msg);
      return;
    }
    if (this.selectedUsers.length >= this.maxEmployees) {
      const msg = this.$t("snack.comp.empmax").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.creating = true;
    try {
      const endpoint = "/api/Company/CreateCompany";
      const data = {
        companyName: this.companyName,
        vatId: this.vatId,
        maxEmployees: this.maxEmployees,
        userEmails: this.selectedUsers || [],
      };
      const res = await api.post(endpoint, data);
      this.createCompany(res);
    } catch (error) {
      console.log(error);
      const msg = this.$t("snack.comp.createfail").toString();
      this.displaySnackbar(msg);
    }
    this.creating = false;
  }
  createCancel() {
    this.createDialog = false;
    this.step = 1;
    this.companyName = "";
    this.maxEmployees = 5;
    this.vatId = "";
    this.selectedUsers = [];
  }

  @Watch("createCompanyLoading")
  nextStep() {
    const condition = !this.createCompanyLoading && this.createCompanySuccess;
    if (condition) this.step += 1;
    return;
  }

  created() {
    const { tier } = this.$route.query;
    switch (tier) {
      default:
      case Tiers.basic:
        this.maxEmployees = 5;
        break;
      case Tiers.premium:
        this.maxEmployees = 10;
        break;
      case Tiers.enterprise:
        this.maxEmployees = 100;
        break;
    }
  }
}
