













































import Vue from "vue";
import { Getter } from "vuex-class";
import { Company } from "@/core/models";
import * as pmTypes from "../components/pmTypes";
import { Component } from "vue-property-decorator";
import { Stripe, loadStripe } from "@stripe/stripe-js";

import CreateCompanyForm from "../components/CreateCompanyForm.vue";
import BillingDetailsForm from "../components/BillingDetailsForm.vue";
import PurchaseOverview from "../components/PurchaseOverview.vue";
import * as Settings from "@/settings";

@Component({
  components: {
    CreateCompanyForm,
    BillingDetailsForm,
    PurchaseOverview,
  },
})
export default class CompanyPurchase extends Vue {
  @Getter("company/company") company!: Company;
  @Getter("company/hasCompany") hasCompany!: boolean;

  step = 1;
  maxEmployees = 5;
  stripeLoading = false;
  stripe: Stripe | null = null;
  paymentMethodType = pmTypes.card;
  paymentMethodId = "";
  async load() {
    this.stripeLoading = true;
    this.stripe = await loadStripe(Settings.paymentSettings.publishableKey);
    this.stripeLoading = false;
  }

  get name() {
    if (!this.company) return "A new Company";
    const { Name } = this.company;
    return Name;
  }
  get tier() {
    const { tier } = this.$route.query;
    if (!tier) this.$router.push("/company/pricing");
    return tier;
  }

  created() {
    this.load();
  }
}
