import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-h3 font-weight-bold"},[_vm._v("Purchase Subscription")]),_c('h2',{staticClass:"text-h4 font-weight-light mb-6"},[_vm._v("For: "+_vm._s(_vm.name))]),_c(VStepper,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"step":1,"editable":""}},[_vm._v("Billing")]),_c(VDivider),_c(VStepperStep,{attrs:{"step":2,"editable":""}},[_vm._v("Overview")])],1),_c(VStepperItems,[_c(VStepperContent,{attrs:{"step":1}},[_c('BillingDetailsForm',{attrs:{"stripe":_vm.stripe,"loading":_vm.stripeLoading,"step-prop":_vm.step,"pm-prop":_vm.paymentMethodType,"pm-id-prop":_vm.paymentMethodId},on:{"update:stepProp":function($event){_vm.step=$event},"update:step-prop":function($event){_vm.step=$event},"update:pmProp":function($event){_vm.paymentMethodType=$event},"update:pm-prop":function($event){_vm.paymentMethodType=$event},"update:pmIdProp":function($event){_vm.paymentMethodId=$event},"update:pm-id-prop":function($event){_vm.paymentMethodId=$event}}})],1),_c(VStepperContent,{attrs:{"step":2}},[_c('PurchaseOverview',{attrs:{"tier":_vm.tier,"stripe":_vm.stripe,"step-prop":_vm.step,"loading":_vm.stripeLoading,"pm-id":_vm.paymentMethodId,"company-id":_vm.company.ID},on:{"update:stepProp":function($event){_vm.step=$event},"update:step-prop":function($event){_vm.step=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }