import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.createConfirm($event)}}},[_c(VCard,[_c(VCardTitle,[_vm._v("Create a New Company")]),_c(VDivider),_c(VCardText,[_c(VTextField,{staticClass:"mb-4",attrs:{"label":"Company Name","error-messages":_vm.nameErrors},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c(VTextField,{staticClass:"mb-4",attrs:{"label":"VAT ID","hint":"Leave blank if you don't have one"},model:{value:(_vm.vatId),callback:function ($$v) {_vm.vatId=$$v},expression:"vatId"}}),_c(VSelect,{staticClass:"mb-4",attrs:{"hide-details":"","items":_vm.items,"label":"Maximum Number of Employees","hint":"This number determines the subscription"},model:{value:(_vm.maxEmployees),callback:function ($$v) {_vm.maxEmployees=_vm._n($$v)},expression:"maxEmployees"}}),_c('EmailsInput',{attrs:{"label":"Select Employees","emails-prop":_vm.selectedUsers},on:{"update:emailsProp":function($event){_vm.selectedUsers=$event},"update:emails-prop":function($event){_vm.selectedUsers=$event}}})],1),_c(VDivider,{staticClass:"my-4"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"accent"},on:{"click":_vm.createCancel}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v("Cancel")])]),_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.createCompanyLoading || _vm.creating,"disabled":_vm.createCompanyLoading || _vm.creating},on:{"click":_vm.createConfirm}},[_c('span',[_vm._v("Create")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }